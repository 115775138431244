import React, { useMemo } from "react"
import { StructuredText } from "react-datocms"

import {
  MultiCardWrap,
  MultiColumnCard,
} from "../../../../styles/Components/SectionContainer/SectionContainerV2Styles"
import Img from "../../../../utils/OptimizedImage"
import Button from "../../../Button"

const CardItems = ({ multiColumn }) => {
  const columnCardItems = useMemo(
    () =>
      multiColumn &&
      multiColumn.length > 0 &&
      multiColumn.map((item, index) => (
        <MultiColumnCard key={index} lg={6}>
          {item?.thumbnail &&
            (item?.thumbnail?.gatsbyImageData ? (
              <Img
                className="image-wrapper"
                image={item?.thumbnail?.gatsbyImageData}
                alt={item?.thumbnail?.alt || item?.heading}
              />
            ) : (
              <Img
                className="image-wrapper"
                src={item?.thumbnail?.url}
                alt={item?.thumbnail?.alt || item?.heading}
              />
            ))}
          {item?.eyebrow && <div className="eyebrow">{item?.eyebrow}</div>}
          {item?.heading && <div className="heading">{item?.heading}</div>}
          {item?.body?.value && (
            <div className="description">
              <StructuredText
                data={item?.body}
                renderInlineRecord={({ record }) => {
                  switch (record.model?.apiKey) {
                    case "component_call_to_action":
                      return (
                        <Button link={record?.url} variant="red">
                          {record?.label}
                        </Button>
                      )
                    default:
                      return null
                  }
                }}
              />
            </div>
          )}
        </MultiColumnCard>
      )),
    [multiColumn]
  )

  return <MultiCardWrap>{columnCardItems}</MultiCardWrap>
}

export default CardItems

import React from "react"

import Button from "../../Button"

const NestedCtabtn = ({ item, idx }) => (
  <Button
    variant={idx % 2 === 0 ? "secondary" : "secondary-outline"}
    link={`${item?.url}`}
  >
    {item?.label}
  </Button>
)

export default NestedCtabtn

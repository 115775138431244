import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const AccordionWrapper = styled.div`
  max-width: 506px;
  width: 100%;
  margin: 0 auto;
`

export const AccordionItemWrapper = styled.div`
  .accordion {
    border-bottom: 1px solid ${color.gray[400]};
    button {
      width: 100%;
      position: relative;
      background-color: ${color.common.transparent};
      padding: 24px 48px 24px 16px;
      border: 0;
      font-size: 24px;
      font-weight: 900;
      line-height: 32px;
      color: ${color.oxfordBlue[900]};
      text-align: left;
      @media (max-width: 767.98px) {
        font-size: 18px;
        line-height: 24px;
      }
      .arrowIcon {
        position: absolute;
        right: 16px;
        transition: all 450ms ease-in-out;
        &.expanded {
          transform: rotate(180deg);
        }
      }
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
    .hide-me {
      display: none;
      height: 0;
      transition: height 0.35s ease-out;
    }
    .accordion-body {
      padding: 0 48px;
      font-size: 18px;
      @media (max-width: 767.98px) {
        font-size: 16px;
      }
      &.with-icon {
        padding-left: 72px;
      }
      p {
        margin-bottom: 24px;
        color: ${color.copy[700]};
      }
      a {
        text-decoration: underline;
      }
      .cta {
        color: ${color.primary[700]};
        font-weight: 850;
        font-size: 18px;
        line-height: 24px;
        text-decoration: none;
        position: relative;
        padding-right: 14px;
        .chevron::before {
          border-style: solid;
          border-width: 2px 2px 0 0;
          content: "";
          display: inline-block;
          height: 0.5em;
          position: relative;
          top: 0;
          left: 6px;
          transform: rotate(45deg);
          width: 0.5em;
        }
        &:hover {
          color: ${color.primary[200]};
        }
        @media (max-width: 767.98px) {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
`

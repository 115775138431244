import React, { useState } from "react"
import Accordion from "react-bootstrap/Accordion"
import { useAccordionButton } from "react-bootstrap/AccordionButton"
import { StructuredText } from "react-datocms"

import { AccordionItemWrapper } from "../../../../styles/Components/SectionContainer/Accordion.styled"
import { BlueArrowDown } from "../../../../utils/IconsList"
import OptimizedImage from "../../../../utils/OptimizedImage"

const AccordionItem = ({ item, id }) => {
  const [selected, setSelected] = useState(null)
  const title = item?.heading
  const icon = item?.icon
  const body = item?.body

  const CustomToggle = ({ eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      selected !== title ? setSelected(title) : setSelected(null)
    )

    return (
      <button type="button" onClick={decoratedOnClick}>
        {icon && icon?.url && (
          <OptimizedImage src={icon?.url} alt={icon?.alt || "accordion icon"} />
        )}
        <span>{title}</span>
        <span className={`arrowIcon ${selected === title ? "expanded" : ""}`}>
          <BlueArrowDown className="align-middle mr-1" />
        </span>
      </button>
    )
  }

  return (
    <AccordionItemWrapper>
      <Accordion>
        <CustomToggle eventKey={id} />
        <Accordion.Collapse eventKey={id}>
          <div className={`accordion-body ${icon ? `with-icon` : ``}`}>
            <StructuredText
              data={body}
              renderInlineRecord={({ record }) => (
                <a className="cta" href={record?.url}>
                  {record.label}
                  <span className="chevron" />
                </a>
              )}
            />
          </div>
        </Accordion.Collapse>
      </Accordion>
    </AccordionItemWrapper>
  )
}

export default AccordionItem

import { Row } from "react-bootstrap"
import styled from "styled-components"

import { color } from "../../../atoms/colors"

export const SwitchbackContainer = styled(Row)`
  .switchback-wrapper {
    padding: 0 15px;
    &.top-padding {
      padding-top: 48px;
    }
    &.bottom-padding {
      padding-bottom: 48px;
    }
    &.download-kit {
      padding: 40px 30px;
      @media screen and (min-width: 768px) {
        padding: 56px 30px;
      }
      @media screen and (min-width: 992px) {
        padding: 96px 30px;
      }
      @media screen and (min-width: 1200px) {
        padding: 96px 15px;
      }
      .order-lg-12 {
        order: 12;
      }
      .switchback-overall {
        @media screen and (min-width: 768px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          column-gap: 0;
        }
        @media screen and (min-width: 992px) {
          column-gap: 10px;
        }
        @media screen and (min-width: 1200px) {
          column-gap: 100px;
        }
        & > div {
          width: 100%;
          max-width: unset;
        }
      }
      h2 {
        font-weight: 900;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.96px;
        @media screen and (min-width: 576px) {
          font-size: 30px;
          line-height: 38px;
        }
        @media screen and (min-width: 992px) {
          font-size: 36px;
          line-height: 44px;
          letter-spacing: -0.02em;
        }
      }
      p {
        font-size: 18px;
        line-height: 24px;
        color: ${color.copy[700]};
      }
      .download-button {
        color: ${color.primary[700]};
        font-size: 18px;
        margin-top: 20px;

        &:hover {
          color: ${color.primary[800]};
          svg {
            path {
              stroke: ${color.primary[800]};
            }
          }
        }
        svg {
          margin-left: 10px;
          fill: none;
          path {
            transition: all 0.5s;
            stroke: ${color.primary[700]};
          }
        }
      }
      .img-wrapper {
        margin: 40px 0 0 0;
        @media screen and (min-width: 768px) {
          margin: 0;
        }
      }
    }
  }
`

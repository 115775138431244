import Lottie from "lottie-react"
import React, { useEffect, useState } from "react"

import fetchLottie from "../../../utils/fetchLottie"

const SwitchbackLottie = ({ lottie }) => {
  const [animation, setAnimation] = useState()

  useEffect(() => {
    if (lottie) {
      fetchLottie(lottie, setAnimation)
    }
  }, [lottie])

  return <Lottie animationData={animation} delay={0} loop />
}

export default SwitchbackLottie

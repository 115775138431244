import { render } from "datocms-structured-text-utils"
import React from "react"
import { Col } from "react-bootstrap"
import { StructuredText } from "react-datocms"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"

import Heading from "../../../../utils/OptimizedHeading"
import Img from "../../../../utils/OptimizedImage"
import IsTablet from "../../../../utils/screenResponsive"

SwiperCore.use([Navigation])

const MultiCarousel = ({ multiColumn, iconSize }) => {
  return (
    <Col lg={12}>
      <Swiper
        navigation={true}
        slidesPerView={IsTablet() ? 1 : 4}
        loop={true}
        spaceBetween={30}
      >
        {multiColumn &&
          multiColumn.length > 0 &&
          multiColumn.map((icon, index) => (
            <SwiperSlide key={index}>
              {icon?.eyebrow && (
                <div className="multi-eyebrow">{icon?.eyebrow}</div>
              )}
              {icon?.icon?.url && (
                <figure className="img-wrapper">
                  <Img
                    src={icon?.icon?.url}
                    alt={icon?.icon?.alt || icon?.icon?.title || icon?.heading}
                    className={`${iconSize}`}
                  />
                </figure>
              )}
              {icon?.heading && (
                <Heading
                  type={icon?.headingSize}
                  string={icon?.heading}
                  className="iconheading"
                />
              )}
              {render(icon?.body)?.length > 0 && (
                <div className="description">
                  <StructuredText
                    data={icon?.body}
                    renderInlineRecord={({ record }) => {
                      switch (record?.model?.apiKey) {
                        case "component_call_to_action":
                          return (
                            <a className="arrow-link" href={record?.url}>
                              {record?.label}
                            </a>
                          )
                        default:
                          return null
                      }
                    }}
                  />
                </div>
              )}
            </SwiperSlide>
          ))}
      </Swiper>
    </Col>
  )
}

export default MultiCarousel

import React, { memo } from "react"
import { Col } from "react-bootstrap"
import { StructuredText } from "react-datocms"
import SwiperCore, { Navigation } from "swiper"
import "swiper/swiper-bundle.css"

SwiperCore.use([Navigation])

const NestedListItem = component => {
  const list = component?.component?.components
  return (
    <>
      {list.map((item, idx) => (
        <Col lg={6} key={idx}>
          <h3>{item?.label}</h3>
          <div className="description">
            <StructuredText data={item?.description} />
          </div>
        </Col>
      ))}
    </>
  )
}

export default memo(NestedListItem)

import React from "react"

import { AccordionWrapper } from "../../../../styles/Components/SectionContainer/Accordion.styled"

import AccordionItem from "./AccordionItem"

const NestedAccordion = ({ accordions }) => (
  <AccordionWrapper>
    {accordions &&
      accordions.map((item, index) => (
        <AccordionItem item={item} key={index} id={index} />
      ))}
  </AccordionWrapper>
)

export default NestedAccordion

import React from "react"
import { Row, Col } from "react-bootstrap"
import { StructuredText } from "react-datocms"
import SwiperCore, { Navigation } from "swiper"

import { SwitchbackContainer } from "../../../styles/Components/SectionContainer/switchback"
import { IconDownload } from "../../../utils/IconsList"
import Heading from "../../../utils/OptimizedHeading"
import Img from "../../../utils/OptimizedImage"
import Button from "../../Button"

import SwitchbackLottie from "./SwitchbackLottie"

import "swiper/swiper-bundle.css"

SwiperCore.use([Navigation])

const NestedSwitchBack = ({ component }) => {
  const switchBack =
    component?.components?.length > 0 &&
    component?.components.filter(
      (item) => item?.model?.apiKey === "component_switchback"
    )

  return (
    <SwitchbackContainer
      className={`justify-content-center align-items-center`}
    >
      {switchBack &&
        switchBack.length > 0 &&
        switchBack.map((item, index) => (
          <Col
            lg={12}
            key={index}
            className={`switchback-wrapper ${
              item?.topPadding ? "top-padding" : ""
            } ${item?.bottomPadding ? "bottom-padding" : ""} ${
              item?.attachDownload ? "download-kit" : ""
            }`}
          >
            <Row className="align-items-center switchback-overall">
              <Col
                md={item?.lottie ? 7 : 6}
                className={item?.reverse ? "order-lg-12" : ""}
              >
                {item?.video?.url && (
                  <video className="video-wrapper" autoPlay loop muted preload>
                    <source
                      src={item?.video?.url}
                      type={item?.video?.mimeType}
                    />
                  </video>
                )}
                {item?.attachmentToDownload?.url ? (
                  item?.image?.gatsbyImageData ? (
                    <a href={item?.attachmentToDownload?.url}>
                      <figure className="img-wrapper">
                        <Img
                          image={item?.image?.gatsbyImageData}
                          alt={item?.image?.alt || item?.image?.title}
                        />
                      </figure>
                    </a>
                  ) : (
                    item?.image?.url && (
                      <a href={item?.attachmentToDownload?.url}>
                        <figure className="img-wrapper">
                          <Img
                            src={item?.image?.url}
                            alt={item?.image?.alt || item?.image?.title}
                          />
                        </figure>
                      </a>
                    )
                  )
                ) : item?.image?.gatsbyImageData ? (
                  <figure className="img-wrapper">
                    <Img
                      image={item?.image?.gatsbyImageData}
                      alt={item?.image?.alt || item?.image?.title}
                    />
                  </figure>
                ) : (
                  item?.image?.url && (
                    <figure className="img-wrapper">
                      <Img
                        src={item?.image?.url}
                        alt={item?.image?.alt || item?.image?.title}
                      />
                    </figure>
                  )
                )}
                {item?.lottie?.url && !item?.image && (
                  <div
                    className={`lottie-area ${
                      component?.imageStyle !== "Full Width"
                        ? ""
                        : "containedheroimg"
                    }`}
                  >
                    <SwitchbackLottie lottie={item?.lottie?.url} />
                  </div>
                )}
              </Col>
              <Col
                md={item?.lottie ? 5 : 6}
                className="align-self-center content"
              >
                {item?.eyebrow && (
                  <p className="heading-kicker itemeyebrow">{item?.eyebrow}</p>
                )}
                {item?.heading && (
                  <Heading
                    type={item?.headingSize}
                    string={item?.heading}
                    className="itemheading"
                  />
                )}
                {item?.switchBackbody && (
                  <div className="description">
                    <StructuredText
                      data={item?.switchBackbody}
                      renderInlineRecord={({ record }) => {
                        switch (record.model?.apiKey) {
                          case "list_item":
                            return (
                              <div className="listitem">
                                {record?.icon?.gatsbYImageData ? (
                                  <Img
                                    image={record?.icon?.gatsbyImageData}
                                    alt={
                                      record?.icon?.alt || record?.icon?.title
                                    }
                                  />
                                ) : (
                                  record?.icon?.url && (
                                    <Img
                                      src={record?.icon?.url}
                                      alt={
                                        record?.icon?.alt || record?.icon?.title
                                      }
                                    />
                                  )
                                )}
                                <p>{record?.label}</p>
                              </div>
                            )
                          case "component_call_to_action":
                            return (
                              <a className="arrow-link" href={record?.url}>
                                {record?.label}
                              </a>
                            )
                          default:
                            return null
                        }
                      }}
                      renderBlock={({ record }) => {
                        const btnTheme = {
                          primary: "light-blue",
                          secondary: "primary-new",
                        }
                        switch (record.__typename) {
                          case "DatoCmsButton":
                            return (
                              <Button
                                variant={btnTheme[record?.theme]}
                                link={record?.url}
                              >
                                {record?.label}
                              </Button>
                            )
                          default:
                            return null
                        }
                      }}
                    />
                  </div>
                )}
                {item?.downloadButtonText && (
                  <Button
                    className="download-button"
                    variant="text-link"
                    link={item?.attachmentToDownload?.url}
                  >
                    {item?.downloadButtonText}
                    <IconDownload />
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        ))}
    </SwitchbackContainer>
  )
}

export default NestedSwitchBack

import React from "react"
import { Row, Col } from "react-bootstrap"

import SectionHeader from "../SectionHeader"

import MultiCarousel from "./Carousel"
import CardItems from "./VariantCard"
import StandardItems from "./VariantStandard"

const NestedMultiColumn = ({ isRightArranged, slug, component }) => {
  const multiColumn =
    component?.components?.length > 0 &&
    component?.components.filter(
      (item) => item?.model?.apiKey === "component_multi_column_content"
    )

  const iconSize = component?.components[0]?.iconSize
  const variant = component?.components[0]?.variant

  return (
    <Row className="justify-content-left">
      {isRightArranged && (
        <Col sm={12} md={12} lg={6}>
          <SectionHeader component={component} />
        </Col>
      )}
      {component?.carrousel ? (
        <MultiCarousel multiColumn={multiColumn} iconSize={iconSize} />
      ) : isRightArranged ? (
        <Col sm={12} md={12} lg={6}>
          <Row>
            <StandardItems
              multiColumn={multiColumn}
              component={component}
              slug={slug}
              isRightArranged={isRightArranged}
              iconSize={iconSize}
            />
          </Row>
        </Col>
      ) : variant === "card" ? (
        <CardItems multiColumn={multiColumn} />
      ) : (
        <StandardItems
          multiColumn={multiColumn}
          component={component}
          slug={slug}
          isRightArranged={isRightArranged}
          iconSize={iconSize}
        />
      )}
    </Row>
  )
}

export default NestedMultiColumn

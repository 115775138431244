import { render } from "datocms-structured-text-to-plain-text"
import React, { useMemo } from "react"
import { Col } from "react-bootstrap"
import { StructuredText } from "react-datocms"

import Heading from "../../../../utils/OptimizedHeading"
import Img from "../../../../utils/OptimizedImage"

const StandardItems = ({
  multiColumn,
  slug,
  isRightArranged,
  iconSize,
  component,
}) => {
  const whatsapp = slug === "products/whatsapp/"
  const promotion =
    slug === "products/promotions" || slug === "products/promotions-new"
  const jobs = slug === "jobs"

  const columnItems = useMemo(
    () =>
      multiColumn &&
      multiColumn.length > 0 &&
      multiColumn.map((item, index) => (
        <Col
          sm={12}
          md={6}
          key={index}
          lg={
            isRightArranged
              ? 6
              : item?.width === "6 Column"
              ? 2
              : item?.width === "4 Column"
              ? 3
              : item?.width === "3 Column"
              ? 4
              : item?.width === "2 Column"
              ? 6
              : multiColumn?.length % 3 === 0
              ? 4
              : multiColumn?.length > 4
              ? 4
              : 12 / multiColumn?.length
          }
          className={`multicolumn-component text-${
            item?.alignment === "Left"
              ? "left"
              : item?.alignment === "Right"
              ? "right"
              : "center"
          }`}
        >
          {item?.eyebrow && (
            <div className="multi-eyebrow">{item?.eyebrow}</div>
          )}
          {item?.thumbnail?.gatsbyImageData ? (
            <div className="thumbnail-wrapper">
              <Img
                image={item?.thumbnail?.gatsbyImageData}
                alt={item?.thumbnail?.alt || item?.heading}
              />
            </div>
          ) : (
            item?.thumbnail?.url && (
              <div className="thumbnail-wrapper">
                <Img
                  image={item?.thumbnail?.url}
                  alt={item?.thumbnail?.alt || item?.heading}
                />
              </div>
            )
          )}
          {item?.icon?.gatsbyImageData ? (
            <figure className="img-wrapper">
              <Img
                image={item?.icon?.gatsbyImageData}
                alt={item?.icon?.alt || item?.icon?.title || item?.heading}
                className={`${whatsapp ? "square-img" : ""} ${
                  promotion ? "flag-img" : ""
                } ${iconSize} ${
                  jobs && component?.heading ? "person-img" : ""
                }`}
              />
            </figure>
          ) : (
            item?.icon?.url && (
              <figure className="img-wrapper">
                <Img
                  src={item?.icon?.url}
                  alt={item?.icon?.alt || item?.icon?.title || item?.heading}
                  className={`${whatsapp ? "square-img" : ""} ${
                    promotion ? "flag-img" : ""
                  } ${iconSize} ${
                    jobs && component?.heading ? "person-img" : ""
                  }`}
                />
              </figure>
            )
          )}
          {item?.heading && (
            <Heading
              type={item?.headingSize}
              string={item?.heading}
              className="iconheading"
            />
          )}
          {render(item?.body)?.length > 0 && (
            <div className="description">
              <StructuredText
                data={item?.body}
                renderInlineRecord={({ record }) => {
                  switch (record.model?.apiKey) {
                    case "component_call_to_action":
                      return (
                        <a className="arrow-link" href={record?.url}>
                          {record?.label}
                        </a>
                      )
                    default:
                      return null
                  }
                }}
              />
            </div>
          )}
        </Col>
      )),
    [multiColumn]
  )

  return columnItems
}

export default StandardItems

import { Col, Row } from "react-bootstrap"
import styled from "styled-components"

import { color } from "../../../atoms/colors"
import checkMarker from "../../../images/checkmark-new.svg"

export const SectionStylesV2 = styled.div`
  position: relative;
  &.bg-sprite {
    .eyebrow {
      border: 2px solid ${color.oxfordBlue[600]};
      color: ${color.oxfordBlue[600]};
    }
  }
  &.new.bg-plum {
    .switchback-container {
      .switchback-overall .content {
        div.description p {
          color: ${color.copy[200]};
        }
      }
    }
  }
  &.bg-plum {
    & .heading-container {
      .heading {
        color: ${color.common.white};
      }

      .description p {
        color: ${color.copy[200]};

        a {
          text-decoration: underline;
        }
      }
    }

    .itemheading {
      color: ${color.common.white} !important;
    }

    .multicolumn-container .iconheading {
      color: ${color.common.white};
    }

    .switchback-container {
      .description {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: ${color.common.white};
          margin-bottom: 24px;
        }
      }
    }
  }
  &.bg-lightgradient {
    .heading {
      color: ${color.oxfordBlue[900]};
      font-size: 48px;
      font-weight: 900;
      line-height: 60px;
      letter-spacing: -0.02em;
      @media (max-width: 767.98px) {
        font-size: 36px;
        line-height: 44px;
      }
      @media (max-width: 575.98px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .heading-container {
      padding-bottom: 64px;
      &.accordion {
        padding-bottom: 32px;
        .description {
          font-size: 18px;
          line-height: 28px;
          @media (max-width: 575.98px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .heading {
          margin-bottom: 16px;
        }
      }
      .eyebrow {
        font-size: 16px;
        font-weight: 900;
        line-height: 20px;
        display: inline-block;
        margin-bottom: 16px;
        color: ${color.primary[700]};
        @media (max-width: 575.98px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .heading {
        color: ${color.oxfordBlue[900]};
        font-size: 48px;
        font-weight: 900;
        line-height: 60px;
        letter-spacing: -0.02em;
        margin-bottom: 16px;
        @media (max-width: 767.98px) {
          font-size: 36px;
          line-height: 44px;
        }
        @media (max-width: 575.98px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      h2 {
        font-size: 36px;
        line-height: 44px;
        @media (max-width: 767.98px) {
          font-size: 30px;
          line-height: 38px;
        }
        @media (max-width: 575.98px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .description {
        font-size: 20px;
        line-height: 30px;
        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
        @media (max-width: 767.98px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .multicolumn-container {
      color: ${color.oxfordBlue[900]};
      text-align: left;
      .iconheading {
        margin-bottom: 16px;
        font-weight: 900;
      }
      .multicolumn-component {
        width: 370px;
        margin-bottom: 16px;
        &:last-child {
          @media (max-width: 576px) {
            margin-bottom: 0px;
          }
        }
        .multi-eyebrow {
          margin-bottom: 16px;
          font-weight: 900;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: ${color.gray[400]};
          @media (max-width: 575.98px) {
            font-size: 12px;
            line-height: 18px;
          }
        }
        @media (max-width: 767.98px) {
          width: 100%;
        }
      }
      @media (max-width: 991.98px) {
        padding: 24px 0;
      }
      @media (max-width: 767.98px) {
        padding: 30px 0;
      }
      @media (max-width: 576px) {
        padding: 0px;
      }
    }
    @media (max-width: 991.98px) {
      padding: 56px;
    }
    @media (max-width: 767.98px) {
      padding: 40px 30px 9px 40px;
    }
  }

  .partnership-container {
    background-color: ${color.aliceBlue[50]};
    padding: 3rem;
    .multicolumn-container {
      &.box-container {
        border: none;
        padding: 0;
        background: ${color.common.transparent};
      }
    }
  }
  .heading-container {
    padding-bottom: 64px;
    &.accordion {
      padding-bottom: 32px;
      .description {
        font-size: 18px;
        line-height: 28px;
        @media (max-width: 575.98px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
      .heading {
        margin-bottom: 16px;
      }
    }
    h1 {
      font-size: 48px;
      line-height: 60px;
      @media (min-width: 991.98px) {
        font-size: 36px;
        line-height: 44px;
      }
      @media (max-width: 575.98px) {
        font-size: 30px;
        line-height: 38px;
      }
    }
    h2 {
      font-size: 36px;
      line-height: 44px;
      @media (max-width: 767.98px) {
        font-size: 30px;
        line-height: 38px;
      }
      @media (max-width: 575.98px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .heading {
      color: ${color.oxfordBlue[900]};
      font-size: 48px;
      font-weight: 900;
      line-height: 60px;
      letter-spacing: -0.02em;
      @media (max-width: 767.98px) {
        font-size: 36px;
        line-height: 44px;
      }
      @media (max-width: 575.98px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .description {
      color: ${color.daveysGray[800]};
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 28px;

      a {
        text-decoration: underline;
      }
    }
  }
  .multicolumn-container {
    position: relative;
    z-index: 1;
    &.floatabove {
      margin-top: -50px;
    }
    &.floatbelow {
      margin-bottom: -100px;
    }
    .thumbnail-wrapper {
      height: 200px;
      width: auto;
      position: relative;
      overflow: hidden;
      margin-bottom: 24px;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
    .img-wrapper {
      margin-bottom: 16px;
      img {
        width: 48px;
        height: 48px;
        &.square-img {
          width: 48px;
          height: 48px;
        }
        &.flag-img {
          width: 56px;
          height: auto;
        }
        &.person-img {
          width: 150px;
          height: 150px;
        }
      }
    }
    .row {
      row-gap: 64px;
      @media (max-width: 992px) {
        row-gap: 24px;
      }
    }
    .multicolumn-component {
      .multi-eyebrow {
        margin-bottom: 16px;
        font-weight: 900;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: ${color.gray[400]};
        @media (max-width: 575.98px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
    .iconheading {
      color: ${color.oxfordBlue[900]};
      font-weight: 900;
    }
    .description {
      color: ${color.copy[700]};
      a {
        text-decoration: underline;
        font-size: 16px;
        line-height: 24px;
      }
      .arrow-link {
        position: relative;
        text-decoration: none;
        font-weight: 700;
        font-size: 18px;
        padding-right: 15px;
        top: 8px;
        cursor: pointer;
        &:hover {
          color: ${color.primary[200]};
        }
        &:before,
        &:after {
          border-right: 2px solid;
          content: "";
          display: block;
          height: 8px;
          margin-top: -6px;
          position: absolute;
          transform: rotate(135deg);
          right: 0;
          top: 54%;
          width: 0;
        }
        :after {
          margin-top: -1px;
          transform: rotate(45deg);
        }
      }
    }
    &.box-container {
      border: 1px solid ${color.copy[100]};
      padding: 30px 55px 30px;
      background: ${color.common.white};
      border-radius: 25px;
    }
  }
  .switchback-container {
    position: relative;
    .switchback-wrapper {
      ul {
        list-style-type: none;
      }
      img {
        border-radius: 12px;
      }
      .listitem {
        img {
          border-radius: 0;
        }
      }
    }
    &.floatabove {
      margin-top: -50px;
    }
    &.floatbelow {
      margin-bottom: -100px;
    }
    .lottie-area {
      position: relative;
      z-index: 2;
    }
    .video-wrapper {
      width: 100%;
      height: 100%;
      max-height: 600px;
      display: block;
      margin: 0 auto;
    }
    .img-wrapper {
      text-align: center;
      & > img {
        max-width: 100%;
        max-height: 500px;
      }
      .gatsby-image-wrapper {
        max-height: 500px;
        img {
          max-height: 500px;
          object-fit: contain !important;
        }
      }
    }
    .itemeyebrow {
      font-size: 14px;
      font-weight: 500;
      border: 2px solid ${color.secondary[600]};
      border-radius: 40px;
      padding: 7px 15px 3px;
      line-height: 1em;
      display: inline-block;
      margin-bottom: 12px;
      color: ${color.secondary[600]};
    }
    .itemheading {
      color: ${color.oxfordBlue[900]};
      font-style: normal;
      margin-bottom: 24px;
      font-weight: 900;
    }
    .description {
      color: ${color.daveysGray[800]};
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: ${color.oxfordBlue[900]};
      }
      strong {
        color: ${color.common.footerBlue};
      }

      a {
        text-decoration: underline;
      }
      .arrow-link {
        position: relative;
        text-decoration: none;
        font-weight: 700;
        padding-right: 15px;
        cursor: pointer;
        &:before,
        &:after {
          border-right: 2px solid;
          content: "";
          display: block;
          height: 8px;
          margin-top: -6px;
          position: absolute;
          transform: rotate(135deg);
          right: 0px;
          top: 54%;
          width: 0;
        }
        :after {
          margin-top: -1px;
          transform: rotate(45deg);
        }
      }
      a.no-arrow {
        &:before,
        &:after {
          display: none;
        }
      }
      .listitem {
        margin-bottom: 10px;
        width: 50%;
        float: left;
        min-height: 110px;
        @media (max-width: 991px) {
          width: 46%;
        }
        @media (max-width: 768px) {
          width: 100%;
          min-height: fit-content;
        }
        * {
          padding-right: 20px;
        }
        img {
          margin-bottom: 12px;
        }
        &:last-child {
          float: revert;
          @media (max-width: 768px) {
            margin-bottom: 32px;
          }
        }
      }
    }
    ul {
      li {
        margin-bottom: 20px;
        strong {
          color: ${color.oxfordBlue[900]};
        }
        &::before {
          content: "";
          width: 20px;
          height: 20px;
          position: absolute;
          background: url(${checkMarker});
          left: 15px;
        }
        &::marker {
          content: "";
          display: none;
        }
      }
    }
  }
  .swiper-button-prev {
    position: absolute;
    top: 24px;
    left: 0;
    background-color: ${color.oxfordBlue[800]};
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    transition: all 450ms ease-in-out;
    &:hover {
      background-color: ${color.primary[350]};
    }
    &:after {
      font-size: 10px;
      color: ${color.common.white};
      font-weight: 700;
    }
  }
  .swiper-button-next {
    position: absolute;
    top: 24px;
    left: 35px;
    background-color: ${color.oxfordBlue[800]};
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    transition: all 450ms ease-in-out;
    &:hover {
      background-color: ${color.primary[350]};
    }
    &:after {
      font-size: 10px;
      color: ${color.common.white};
      font-weight: 700;
    }
  }
  .swiper-wrapper {
    margin-top: 45px;
  }
  .ctabtn-area {
    margin-bottom: 24px;
    button {
      min-width: 202px;
      box-shadow: 0 4px 4px 0 rgb(0 0 0 / 15%);
      padding: 17px 20px;
      margin: 0 0.5rem 0.5rem;
    }

    &.btn-round {
      button {
        border-radius: 40px;
        font-family: "Avenir", sans-serif;
        font-style: normal;
        font-weight: 900;
        font-size: 16px;
        color: ${color.copy[900]};
        width: fit-content;
        padding: 12px 24px;
        min-width: auto;
      }
    }
  }
  a {
    transition: all 450ms ease-in-out;
    text-decoration: none;
  }
  .listitem-container {
    h3 {
      font-weight: 500;
      font-style: normal;
      font-size: 20px;
      line-height: 25px;
      color: ${color.oxfordBlue[800]};
      padding-left: 30px;
      &::before {
        content: "";
        width: 20px;
        height: 20px;
        position: absolute;
        background: url(${checkMarker});
        left: 15px;
      }
      &::marker {
        content: "";
        display: none;
      }
    }
  }
  &.new {
    padding-top: ${(props) => (props.noPaddingTop ? "0px" : "96px")};
    padding-bottom: ${(props) => (props.noPaddingBottom ? "0px" : "96px")};
    padding-left: 0;
    padding-right: 0;
    @media (max-width: 991.98px) {
      padding-top: ${(props) => (props.noPaddingTop ? "0px" : "56px")};
      padding-bottom: ${(props) => (props.noPaddingBottom ? "0px" : "56px")};
    }
    @media (max-width: 767.98px) {
      padding-top: ${(props) => (props.noPaddingTop ? "0px" : "40px")};
      padding-bottom: ${(props) => (props.noPaddingBottom ? "0px" : "40px")};
    }
    .container {
      max-width: 1230px !important;
      padding: 0px 30px;
      @media (max-width: 991.98px) {
        max-width: 830px !important;
      }
      @media (max-width: 575.98px) {
        max-width: 536px !important;
      }
    }
    .heading-container {
      padding-bottom: 64px;
      &.accordion {
        padding-bottom: 32px;
        .description {
          font-size: 18px;
          line-height: 28px;
          @media (max-width: 575.98px) {
            font-size: 16px;
            line-height: 24px;
          }
        }
        .heading {
          margin-bottom: 16px;
        }
      }
      @media (max-width: 767.98px) {
        padding-bottom: 40px;
      }
      .eyebrow {
        font-size: 14px;
        font-weight: 900;
        line-height: 20px;
        display: inline-block;
        margin-bottom: 16px;
        color: ${color.primary[700]};
        @media (max-width: 575.98px) {
          font-size: 12px;
          line-height: 18px;
        }
      }
      .heading {
        letter-spacing: -0.02em;
        font-weight: 900;
        max-width: 770px;
      }
      h1.heading {
        margin-bottom: 16px;
        font-size: 48px;
        line-height: 60px;
        @media (max-width: 767.98px) {
          font-size: 36px;
          line-height: 44px;
        }
        @media (max-width: 575.98px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      h2.heading {
        font-size: 36px;
        line-height: 44px;
        @media (max-width: 575.98px) {
          font-size: 24px;
          line-height: 32px;
        }
      }
      .description {
        max-width: 770px;
        color: ${color.copy[700]};
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 30px;
        @media (max-width: 767.98px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
    .ctabtn-area {
      button {
        padding: 12px 24px;
        color: black;
        background-color: ${color.primary[200]};
        border: 1px solid ${color.primary[200]};
        border-radius: 40px;
        font-weight: 700;
        &:hover {
          background: ${color.primary[400]};
          border: 1px solid ${color.primary[400]};
        }
        @media (max-width: 767.98px) {
          width: 100%;
        }
      }
    }
    .multicolumn-container {
      .img-wrapper {
        img {
          &.small {
            width: 32px;
            height: 32px;
          }
          &.medium {
            width: 48px;
            height: 48px;
          }
          &.large {
            width: 80px;
            height: 80px;
          }
          &.xl {
            width: 140px;
            height: 140px;
          }
        }
        .gatsby-image-wrapper {
          &.small {
            width: 32px;
            height: 32px;
            img {
              width: 32px;
              height: 32px;
            }
          }
          &.medium {
            width: 48px;
            height: 48px;
            img {
              width: 48px;
              height: 48px;
            }
          }
          &.large {
            width: 80px;
            height: 80px;
            img {
              width: 80px;
              height: 80px;
            }
          }
          &.xl {
            width: 140px;
            height: 140px;
            img {
              width: 140px;
              height: 140px;
            }
          }
        }
      }
    }
    .switchback-container {
      .switchback-wrapper {
        display: flex;
        &.top-padding {
          padding-top: 48px;
        }
        &.bottom-padding {
          padding-bottom: 48px;
        }
        ul {
          list-style-type: none;
        }
        .switchback-overall {
          max-width: 1170px;
          width: 100%;
          margin: auto;
          .itemeyebrow {
            font-family: "Avenir", sans-serif;
            font-style: normal;
            font-weight: 900;
            font-size: 14px;
            line-height: 20px;
            color: ${color.primary[700]};
            padding: 0;
            border: none;
            border-radius: 0;
            @media (max-width: 575.98px) {
              font-size: 12px;
              line-height: 18px;
            }
          }
          .img-wrapper {
            margin-bottom: 0;
            @media (max-width: 767.98px) {
              padding-top: 40px;
            }
          }
          justify-content: space-between;
          .order-lg-12 {
            @media (min-width: 767.98px) {
              order: 12;
            }
          }
          .content {
            h2 {
              font-size: 36px;
              line-height: 44px;
              @media (max-width: 991.98px) {
                font-size: 30px;
                line-height: 38px;
              }
              @media (max-width: 575.98px) {
                font-size: 24px;
                line-height: 32px;
              }
            }
            .description {
              p {
                font-style: normal;
                font-size: 18px;
                line-height: 24px;
                color: ${color.copy[700]};
                margin-bottom: 16px;
                strong {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 24px;
                  color: ${color.copy[700]};
                }

                @media (max-width: 768px) {
                  font-size: 16px;
                }
              }
            }
          }
          @media (max-width: 767.98px) {
            flex-direction: column-reverse;
          }
        }
      }
    }
    .bottom-note {
      margin-top: 32px;
      @media (max-width: 991.98px) {
        margin-top: 0;
      }
      p {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: ${color.oxfordBlue[900]};
        @media (max-width: 991.98px) {
          font-size: 20px;
          line-height: 30px;
        }
        @media (max-width: 767.98px) {
          font-size: 18px;
          line-height: 24px;
        }
      }

      .btn-link {
        padding: 0;
      }
    }
  }
`

export const ImageWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  overflow: hidden;
  @media (max-width: 992px) {
    margin-bottom: 40px;
  }
  .featured-img {
    width: 100%;
    height: auto;
  }
`

export const MultiCardWrap = styled(Row)`
  justify-content: space-between;
  @media (max-width: 991.98px) {
    row-gap: 64px !important;
    justify-content: center;
  }
`

export const MultiColumnCard = styled(Col)`
  max-width: 500px !important;
  @media (max-width: 991.98px) {
    max-width: 770px !important;
  }
  @media (max-width: 767.98px) {
    max-width: 506px !important;
  }
  .eyebrow {
    font-family: "Avenir", sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: ${color.primary[700]};
    margin-bottom: 16px;
    @media (max-width: 575.98px) {
      font-size: 12px;
      line-height: 18px;
    }
  }
  .heading {
    font-family: "Avenir", sans-serif;
    font-style: normal;
    font-weight: 850;
    font-size: 30px;
    line-height: 38px;
    color: ${color.oxfordBlue[900]};
    margin-bottom: 16px;
    @media (max-width: 767.98px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  .description {
    font-weight: 350;
    font-size: 18px;
    line-height: 24px;
    color: ${color.copy[700]};
    display: grid;
    grid-gap: 16px;
    button {
      @media (max-width: 767.98px) {
        width: 100%;
      }
    }
  }
  .image-wrapper {
    aspect-ratio: 16 / 9;
    margin-bottom: 16px;
    border-radius: 12px;
    width: 100%;
    img {
      border-radius: 12px;
    }
  }
`

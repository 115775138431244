import { isStructuredText } from "datocms-structured-text-utils"
import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StructuredText } from "react-datocms"
import SwiperCore, { Navigation } from "swiper"

import "swiper/swiper-bundle.css"
import {
  SectionStylesV2,
  ImageWrapper,
} from "../../styles/Components/SectionContainer/SectionContainerV2Styles"
import OptimizedImage from "../../utils/OptimizedImage"
import Button from "../Button"

import NestedAccordion from "./Components/Accordion"
import NestedCtabtn from "./Components/Ctabtn"
import NestedListItem from "./Components/ListItem"
import NestedMultiColumn from "./Components/MultiColumn"
import SectionHeader from "./Components/SectionHeader"
import NestedSwitchBack from "./Components/SwitchBack"

SwiperCore.use([Navigation])

const SectionContainer = ({ component, grid, slug }) => {
  const bgClass = component?.background?.replace(/ /g, "").toLowerCase()
  const multiColumn =
    component?.components?.length > 0 &&
    component?.components.filter(
      (item) => item?.model?.apiKey === "component_multi_column_content"
    )
  const switchBack =
    component?.components?.length > 0 &&
    component?.components.filter(
      (item) => item?.model?.apiKey === "component_switchback"
    )
  const listItem =
    component?.components?.length > 0 &&
    component?.components.filter((item) => item?.model?.apiKey === "list_item")
  const accordion =
    component?.components?.length > 0 &&
    component?.components.filter(
      (item) => item?.model?.apiKey === "component_accordion"
    )
  const ctabtn = component?.callToAction
  const designVersion = component?.newDesign === true ? "new" : ""
  const partnership = slug === "partnership-program"
  const bottomNote = component?.bottomNote
  const bottomNoteString =
    component?.bottomNote?.value?.document?.children[0]?.children[0]?.value
  const isRightArranged = component?.multiColumnRightArranged

  return (
    <SectionStylesV2
      className={`${designVersion} bg-${bgClass} container-${
        grid !== "" ? grid?.toLowerCase() : "normal"
      }`}
      noPaddingTop={component?.noPaddingTop}
      noPaddingBottom={component?.noPaddingBottom}
    >
      {component?.sectionId && (
        <a id={component.sectionId} className="anchor-link"></a>
      )}
      <Container
        className={`${
          partnership && component?.heading === "Partnership Categories"
            ? "partnership-container"
            : ""
        }`}
      >
        {component?.imageFeatured?.gatsbyImageData ? (
          <ImageWrapper>
            <OptimizedImage
              className="featured-img"
              image={component?.imageFeatured?.gatsyImageData}
              alt={component?.imageFeatured?.alt || "Featured Image"}
            />
          </ImageWrapper>
        ) : (
          component?.imageFeatured?.url && (
            <ImageWrapper>
              <OptimizedImage
                className="featured-img"
                src={component?.imageFeatured?.url}
                alt={component?.imageFeatured?.alt || "Featured Image"}
              />
            </ImageWrapper>
          )
        )}
        {!isRightArranged && (
          <Row>
            <Col>
              <SectionHeader
                component={component}
                className={accordion ? `accordion` : ``}
              />
            </Col>
          </Row>
        )}
        {multiColumn && multiColumn.length > 0 && (
          <div
            className={`multicolumn-container ${
              component?.boxShadow ? "box-container" : ""
            } ${component?.floatBelow ? "floatbelow" : ""} ${
              component?.floatAbove ? "floatabove" : ""
            }`}
          >
            <NestedMultiColumn
              isRightArranged={isRightArranged}
              slug={slug}
              component={component}
            />
          </div>
        )}
        {switchBack && switchBack.length > 0 && (
          <div
            className={`switchback-container ${
              component?.floatBelow ? "floatbelow" : ""
            } ${component?.floatAbove ? "floatabove" : ""}`}
          >
            <NestedSwitchBack component={component} slug={slug} />
          </div>
        )}
        {listItem && listItem.length > 0 && (
          <Row className="listitem-container">
            <NestedListItem component={component} />
          </Row>
        )}
        {accordion && accordion.length > 0 && (
          <Row className="accordion-container">
            <NestedAccordion accordions={accordion} />
          </Row>
        )}
        {ctabtn && ctabtn?.length > 0 && (
          <Row>
            <Col
              className={`ctabtn-area text-center mt-3 ${
                component?.boxShadow ? "btn-round" : ""
              }`}
            >
              {ctabtn &&
                ctabtn?.length > 0 &&
                ctabtn.map((item, idx) => (
                  <NestedCtabtn item={item} key={item?.id} idx={idx} />
                ))}
            </Col>
          </Row>
        )}
        {bottomNoteString !== "" && isStructuredText(bottomNote) && (
          <Row className="bottom-note">
            <Col>
              <StructuredText
                data={bottomNote}
                renderInlineRecord={({ record }) => {
                  switch (record?.__typename) {
                    case "DatoCmsComponentCallToAction":
                      return (
                        <Button isWithArrow link={record?.url}>
                          {record?.label}
                        </Button>
                      )
                    default:
                      return null
                  }
                }}
              />
            </Col>
          </Row>
        )}
      </Container>
    </SectionStylesV2>
  )
}

export default SectionContainer

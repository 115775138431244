import { render } from "datocms-structured-text-to-plain-text"
import React from "react"
import { StructuredText } from "react-datocms"

import Heading from "../../../utils/OptimizedHeading"

const SectionHeader = ({ component, className }) => (
  <div
    className={`${className} ${
      component?.heading || component?.featuredImage ? "heading-container" : ""
    } 
    ${
      component?.alignment === "Center"
        ? "text-center align-items-center"
        : component?.alignment === "Left"
        ? "text-left align-items-left"
        : ""
    }`}
  >
    {component?.eyebrow && (
      <p className="heading-kicker eyebrow">{component?.eyebrow}</p>
    )}
    {component?.heading && (
      <Heading
        type={component?.headingSize || "Heading 1"}
        string={component?.heading}
        className={`heading ${
          component?.alignment === "Center" ? "mx-auto" : ""
        }`}
      />
    )}
    {render(component?.sectionBody)?.length > 0 && (
      <div
        className={`description ${
          component?.alignment === "Center" ? "mx-auto" : ""
        }`}
      >
        <StructuredText
          data={component?.sectionBody}
          renderInlineRecord={() => {}}
        />
      </div>
    )}
  </div>
)

export default SectionHeader
